<template>
    <div class="flexPage themePage">
        <div class="flexHead">
            <h3 class="quotesHead flexSpindle ">{{ $t('nav.n2') }}</h3>
        </div>
        <div class="flexContent">
            <div class="secondMenus">
                <div class="menusHeader" style="display: flex;align-items: center;justify-content: center;">
                    <ul class="tab"
                        style="padding:0;border-radius: 5px;display: flex;justify-content: center;border: #3861FB 1px solid;width: fit-content;">
                        <li v-for="(item) of list" :key="item.id" :class="{ card: typeActive == item.id }"
                            :style="typeActive == item.id ? 'padding: 10px 20px;margin: 0;color:#FFFFFF;' : 'padding: 10px 20px;margin: 0;color:#3861FB;'"
                            @click="onChooseTab(item.id)">
                            {{ item.title }}
                        </li>
                    </ul>
                </div>
                <!-- 最新价{{vMarket}} -->
                <div class="tradeListTitle">
                    <span>{{ $t('home.l1') }}</span>
                    <span>{{ $t('home.l2') }}{{ vMarket }}</span>
                    <span>24H {{ $t('home.l3') }}</span>
                </div>
            </div>
            <div v-if="showloading" style="display: flex;justify-content: center;align-items: center;height: 100%;">
                <span>
                    加载中
                </span>
            </div>
            <div v-if="!showloading">
                <nodata v-if="!marketList || marketList.length == 0"></nodata>
                <ul v-if="typeActive == 1" class="coinlist">
                    <li v-for="(item, index) of favorlist" :key="index"
                        @click="$router.push({ name: 'tradeDetail', params: { symbol: item.symbol } })">
                        <div class="clleft">
                            <p><b style="color: #515a6e;font-size: 12px;">{{ item.symbol }}</b></p>
                            <p><span class="vol">24H {{ $t('home.sl') }}</span><span>
                                    {{ (item.turnover).toFixed(2) }}
                                </span></p>
                        </div>
                        <div class="clcenter">
                            <p><b v-html="$tdp.format(item, 'close', 2)" style="color: #515a6e;font-size: 12px;"></b>
                            </p>
                            <p style="width: fit-content;height: fit-content;">{{ (CNYRate * item.close).toFixed(2) }}
                            </p>
                        </div>
                        <div class="clright">
                            <span :class="getClass($tdp.format(item, 'change'))"
                                v-html="$tdp.format(item, 'change')"></span>
                        </div>
                    </li>
                </ul>
                <ul v-if="typeActive != 1" class="coinlist">
                    <li v-for="(item, index) of marketList" :key="index"
                        @click="$router.push({ name: 'tradeDetail', params: { symbol: JSON.stringify(item.symbol) } })">
                        <div class="clleft">
                            <p><b style="color: #515a6e;font-size: 12px;">{{ item.symbol }}</b></p>
                            <p><span class="vol">24H {{ $t('home.sl') }}</span><span>
                                    {{ (item.turnover).toFixed(2) }}
                                </span></p>
                        </div>
                        <div class="clcenter">
                            <p><b v-html="$tdp.format(item, 'close', 2)" style="color: #515a6e;font-size: 12px;"></b>
                            </p>
                            <!-- <p><span class="cur" ></span></p> -->
                            <p style="width: fit-content;height: fit-content;">{{ (CNYRate * item.close).toFixed(2) }}
                            </p>
                        </div>
                        <div class="clright">
                            <span :class="getClass($tdp.format(item, 'change'))"
                                v-html="$tdp.format(item, 'change')"></span>
                        </div>
                    </li>
                    <!-- <li v-for="(item, index) of rankingVO" :key="index"
                        @click="$router.push({ name: 'tradeDetail', params: { symbol: item.symbol + '_' + item.market } })">
                        <div class="clleft">
                            <p><b>{{ item.symbol }}</b><span class="op5">/{{ item.market }}</span></p>
                            <p><span class="vol">24H {{ $t('home.sl') }}</span><span
                                    v-html="$tdp.format(item, 'total')"></span></p>
                        </div>
                        <div class="clcenter">
                            <p><b v-html="$tdp.format(item, 'close')"></b></p>
                            <p><span class="cur" v-html="$tdp.format(item, 'money')"></span></p>
                        </div>
                        <div class="clright">
                            <span class="zdbtn" :class="getClass($tdp.format(item, 'changeRange'))"
                                v-html="$tdp.format(item, 'changeRange')"></span>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
        <downbar />
    </div>
</template>
<script>
import downbar from "@/views/modules/downbar.vue";
import { Button, Loading } from 'vant';
export default {
    data() {
        return {
            typeActive: 2,
            activeTab: '',
            rankingVO: [],
            polling: null,
            vRate: localStorage.getItem('rate') ? localStorage.getItem('rate') : 'CNY',
            rateVO: null,
            favordata: [],
            marketList: [],//行情数据
            CNYRate: null,//汇率
            list: [
                {
                    title: "自选",
                    id: 1
                },
                {
                    title: "全部",
                    id: 2//
                }, {
                    title: "主板",
                    id: 3//MAIN
                }, {
                    title: "创业版",
                    id: 4//GEM
                }
            ],
            favorlist: [],//自选数据
            nav: '',
            showloading: false
        }
    },
    beforeRouteLeave(to, from, next) {
        clearInterval(this.polling);
        next();
    },
    components: { downbar },
    watch: {
        "activeTab": function (cur, old) {
            let that = this;
            if (cur) {
                if (cur == 'OWN') {
                    if (this.$store.state.isLogin) {
                        that.optional();
                    }
                } else {
                    that.ranking();
                }
            }
        },
        "nav": function (cur, old) {
            let that = this
            that.showloading = true
            that.getData();
        }
    },
    computed: {
        vMarket() {
            let that = this;
            if (that.typeActive == -1) {
                return '';
            }

            let n = that.activeTab ? that.activeTab : ''
            return '(' + n + ')';
        },
        markets() {
            let that = this;
            let marketVO = that.$store.state.app.market;
            if (marketVO) {
                that.activeTab = marketVO[0]
                // this.ranking();
            }
            return marketVO;
        }
    },
    beforeDestroy() {
        clearInterval(this.polling);
    },
    mounted() {
        //交易市场
        this.$mts.market()
        this.$mts.rates();
        this.$mts.checkLogin('clear');
        this.pageInit()
        this.getData()
        this.getCNYRate()
        this.getFavor()
    },
    methods: {
        getFavor() {//查询自选数据
            let that = this
            that.$mts.postnew({
                url: "exchange/favor/find",
                data: {},
                success(response) {
                    that.favordata = response.data
                }
            })
        },
        getData() {
            let that = this
            let params = {
                sort: 'symbol', //排序目标
                direction: 'ASC', //排序顺序  ASC和DESC  点击排序sort和direction要一起传递，可都不传
                nav: that.nav,//一级分类板块名  不传参默认全部  MAIN=主板  GEM=创业板
                legalCurrency: 'USDT' //二级分类法币名  不传参默认USDT
            }
            that.$mts.postnew({
                url: 'market/tickers/page-query',
                data: params,
                success(response) {
                    that.marketList = response.data.data
                    that.showloading = false
                    if(that.favordata){
                        that.favorlist = response.data.data.filter((item) => that.favordata.some(i => i.symbol == item.symbol))
                    }
                }
            })
        },
        // 获取人民币利率
        getCNYRate() {
            let that = this
            that.$mts.post({
                url: 'market/exchange-rate/usd-cny',
                data: {},
                success(response) {
                    that.CNYRate = response.data.data
                }
            })
        },
        getClass(num) {
            if (num > 0) {
                return "green"
            } else if (num < 0) {
                return "red"
            } else {
                return "normal"
            }
        },
        onChooseTab(index, item) {
            if (index == 2) {
                this.nav = ''
            }
            if (index == 3) {
                this.nav = 'MAIN'
            }
            if (index == 4) {
                this.nav = 'GEM'
            }
            this.typeActive = index;
            if (index == -1) {
                this.activeTab = 'OWN';
                clearInterval(this.polling);
                this.polling = setInterval(() => {
                    this.optional();
                }, 10000);
            } else {
                this.activeTab = item;
                clearInterval(this.polling);
                this.polling = setInterval(() => {
                    this.getData();
                }, 10000);
            }
        },
        pageInit() {
            if (this.activeTab && this.activeTab == 'OWN') {
                // 设置定时请求
                this.optional();
                this.polling = setInterval(() => {
                    this.optional();
                }, 10000);
            } else {
                // 设置定时请求
                // this.ranking();
                this.getData()
                this.polling = setInterval(() => {
                    // this.ranking();
                    this.getData()
                }, 10000);
            }
        },
        ranking() {
            let that = this;
            that.$mts.post({
                url: 'quotation/ranking',
                data: {
                    market: that.activeTab
                }, success(response) {
                    if (response.data.code == 200) {
                        that.rankingVO = response.data.data;
                    }
                }
            });
        },
        optional() {
            let that = this;
            that.$mts.posts({
                url: 'quotation/optional',
                data: {
                }, success(response) {
                    that.rankingVO = [];
                    if (response.data.code == 200) {
                        for (var key in response.data.data) {
                            that.rankingVO = that.rankingVO.concat(response.data.data[key]);
                        }
                    }
                    console.log(that.rankingVO)
                }
            });
        }

    }
}
</script>

<style scoped>
.card {
    background-color: #3861FB;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
}
</style>
